
import React, { useState, useEffect } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import {useParams, Link, useNavigate } from 'react-router-dom';
import '../../assets/css/home_style.css';
import Header from "./includes/Header";
// import Slider from "./includes/Slider";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import '../../assets/css/slider_style.css'

const SinglePage = ({ google }) => {
    const [headerScroll, setHeaderScroll] = useState(false);
    const [html, setHTML] = useState('');
    const [tourInfo, setTourInfo] = useState( null);
    const [sliderImages, setSliderImages] = useState( []);
    const [image_path, setImagePath] = useState('https://kazanfood.justcode.am/uploads/');

    const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0,  latitudeDelta:0.01,
        longitudeDelta:0.01});




    useEffect(() => {
        // localStorage.clear();
        getTourInfo()

        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }

        window.addEventListener('load', handleLoad);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const { id } = useParams();

    const getTourInfo = () => {


        const requestOptions = {
            method: "GET",
            redirect: "follow"
        };

        fetch(`https://kazanfood.justcode.am/api/single_page_tour?tour_id=${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result, 'tour_single_info');
                let new_images = [];
                if (result.status) {
                    // Checking if status is true
                    if (result?.data?.photo && result?.data?.photo.length > 0) {
                        const newImages = result?.data?.photo.map(item => item.photo);
                        setSliderImages(newImages);
                        console.log(new_images, 'new_images')
                    }


                    setTourInfo(result.data);
                } else {
                    console.error("Error: Status is false");
                }
            })
            .catch((error) => console.error(error));
    };
    const handleLoad = () => {
        // handle load event
    };

    const handleScroll = (ev) => {
        let scrollTop = window.scrollY;

        if (scrollTop > 0) {
            setHeaderScroll(true);
        } else {
            setHeaderScroll(false);
        }
    };

    useEffect(() => {
        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(pos => {
                const coords = pos.coords;
                setUserLocation({
                    lat: coords.latitude,
                    lng: coords.longitude,

                });
            });
        }
    }, []);

    const mapStyles = {
        width: '100%',
        height: '100%'
    };


    return (
        <>

            <div className='main_wrapper'>
                <Header />
                <main>
                    <section className="kazan_sightseeing_single_page">
                        <div className="kazan_sightseeing_single_page_wrapper">
                            <div className="kazan_sightseeing_single_page_slider_map_wrapper">
                                <div className='kazan_sightseeing_single_page_slider'>
                                    <Swiper
                                        grabCursor={true}
                                        slidesPerView={1}
                                        modules={[Autoplay, Pagination, Navigation]}
                                        loop={true}
                                        loopedSlides={1}
                                        pagination={{ clickable: true }}

                                        className="top_slider"
                                    >


                                        {sliderImages.length > 0 && sliderImages?.map((item, index) => (
                                            <SwiperSlide key={index}>
                                                <div className="kazan_slider_item">
                                                    <img src={image_path + item} alt={`Slide ${index}`} />
                                                </div>
                                            </SwiperSlide>
                                        ))}




                                    </Swiper>
                                </div>
                                <div className="kazan_sightseeing_single_page_info_map_wrapper">
                                    <div className="kazan_sightseeing_single_page_title_distance_info_wrapper">
                                        <h1 className='kazan_sightseeing_single_page_title'>{tourInfo?.title}</h1>
                                        <p className='kazan_sightseeing_single_page_distance_info'>{tourInfo?.km} км</p>
                                    </div>
                                    <p className='kazan_sightseeing_single_page_info'>
                                        {tourInfo?.description}
                                    </p>
                                    <div className='kazan_sightseeing_single_page_map'>
                                        <Map
                                            google={google}

                                            zoom={2}
                                            style={mapStyles}
                                            initialCenter={userLocation}
                                        >
                                            <Marker position={userLocation} />
                                        </Map>
                                    </div>

                                </div>
                            </div>
                            <div className='kazan_sightseeing_single_page_audio_list_items_wrapper'>
                                {tourInfo?.audio.map((item,index) => {
                                    return (
                                       <div className='kazan_sightseeing_single_page_audio_list_item' key={item.id} >
                                           <div className="kazan_sightseeing_single_page_audio_list_item_title_step_wrapper">
                                               <p className="kazan_sightseeing_single_page_audio_list_item_step">
                                                   {index + 1}
                                               </p>
                                               {item?.audio_name !== null &&
                                                   <p className="kazan_sightseeing_single_page_audio_list_item_title">
                                                       {item?.audio_name}
                                                   </p>
                                               }

                                           </div>
                                           <AudioPlayer
                                               // autoPlay
                                               src={image_path + item?.audio}
                                               onPlay={e => console.log("onPlay")}
                                               // other props here
                                           />
                                       </div>
                                    )
                                })}
                            </div>

                        </div>
                    </section>
                </main>
            </div>
        </>
    );
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDvgBMu7Qkvh2HL1YDbgJu7dripTMI1Bl8' // Replace with your actual API key
})(SinglePage);

