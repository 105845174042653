import React, { useState, useEffect, useRef } from 'react';
import '../../../assets/css/header_style.css';
import {Link} from "react-router-dom";



export default function Header(props) {
    const [headerScroll, setHeaderScroll] = useState(false);

    useEffect(() => {

        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }

        window.addEventListener('load', handleLoad);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('load', handleLoad);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLoad = () => {};

    const handleScroll = (ev) => {
        const scrollTop = window.scrollY;

        if (scrollTop > 0) {
            setHeaderScroll(true);
        } else {
            setHeaderScroll(false);
        }
    };
    const disableBodyScroll = () => {
        document.body.style.overflow = "hidden";
    };

    const enableBodyScroll = () => {
        document.body.style.overflow = "auto";
    };

    return (
        <>

            <div  className='header' >
                <div className='header_wrapper'>
                    <a href="/" className='header_logo'>
                        <img src={require('../../../assets/images/logo.png')}/>
                    </a>
                    <h1 className="header_title">
                        Kazan<span className='header_title_span'>OnFoot</span>
                    </h1>
                </div>

            </div>

        </>
    );
}
