import React, { useState, useEffect } from 'react';
 import { Link, useNavigate } from 'react-router-dom';
 import '../../assets/css/home_style.css';
import Header from "./includes/Header";

 export default function Home (props) {
     const [headerScroll, setHeaderScroll] = useState(false);
     const [html, setHTML] = useState('');
     const [kazanSightseeingsList, setKazanSightseeingsList] = useState([]);
     const [pageUrl, setPageUrl] = useState('https://kazanfood.justcode.am/api/get_tours?page=1');
     const [image_path, setImagePath] = useState('https://kazanfood.justcode.am/uploads/');

     useEffect(() => {
        // localStorage.clear();

         getTours()


         if ('scrollRestoration' in window.history) {
             window.history.scrollRestoration = 'manual';
         }

         window.addEventListener('load', handleLoad);
         window.addEventListener('scroll', handleScroll);

         return () => {
             window.removeEventListener('load', handleLoad);
             window.removeEventListener('scroll', handleScroll);
         };
     }, []);



     const handleLoad = () => {
     };

     const handleScroll = (ev) => {
         let scrollTop = window.scrollY;

         if (scrollTop > 0) {
             setHeaderScroll(true);
         } else {
             setHeaderScroll(false);
         }
     };

     const navigate = useNavigate();

     // const navigateToNewsCategoryPage = (id) => {
     //     navigate(`/category/${id}`);
     // }
     //

     const navigateToSinglePage = (id) => {
         console.log(id, 'id')
         navigate(`/tour/${id}`);
     }

     const getTours = () => {
         const requestOptions = {
             method: "GET",
             redirect: "follow"
         };
         if (pageUrl !== null) {
             fetch(pageUrl, requestOptions)
                 .then((response) => response.json())
                 .then((result) => {
                     console.log(pageUrl, 'url')
                     if (result?.status === true) {
                         setPageUrl(result?.data?.next_page_url)
                         setKazanSightseeingsList(prevList => {
                             // If KazanSightseeingsList is empty, fill it with new data
                             if (prevList.length === 0) {
                                 return result.data.data;
                             } else {
                                 // If it's not empty, append new data while preserving old data
                                 return [...prevList, ...result.data.data];
                             }
                         });

                     }

                 })
                 .catch((error) => console.error(error));
         }


     }


     return (
         <>


             <div className='main_wrapper'>
                 <Header/>
                     <main>
                         <section className="kazan_sightseeings">
                            <div className="kazan_sightseeings_wrapper">
                                <div className="kazan_sightseeings_items_wrapper">
                                    {kazanSightseeingsList.map((item, index) => {
                                        return (
                                            <div className="kazan_sightseeings_item" key={index} onClick={() => navigateToSinglePage(item?.id)}>
                                                <div className="kazan_sightseeings_item_child1">
                                                    <div className='kazan_sightseeings_item_child1_info_box'>
                                                        <div className="kazan_sightseeings_item_child1_title_distance_info_wrapper">
                                                            <h1 className="kazan_sightseeings_item_child1_title">{item?.title}</h1>
                                                            <p className="kazan_sightseeings_item_child1_distance_info">{item?.km}</p>
                                                        </div>
                                                        <p className='kazan_sightseeings_item_child1_info'>{item?.description}</p>
                                                    </div>

                                                    <div className="kazan_sightseeings_item_child1_img">
                                                        <img src={image_path + item?.photo[0]?.photo} alt=""/>
                                                    </div>
                                                </div>
                                                <div className="kazan_sightseeings_item_child2">
                                                    <div className='kazan_sightseeings_item_child2_img'>
                                                        <img src={image_path + item?.photo[0]?.photo} alt=""/>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                {pageUrl !== null &&

                                    <button className='kazan_sightseeings_load_more_btn' onClick={() => getTours()}>
                                        Загрузить еще
                                        <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none"><rect x="6.84033" width="4" height="17" fill="white"/><rect x="17.3403" y="6.5" width="4" height="17" transform="rotate(90 17.3403 6.5)" fill="white"/></svg></span>
                                    </button>
                                }

                            </div>
                         </section>


                     </main>

             </div>

         </>

     )


 }



